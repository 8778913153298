<template>
  <div class="inicio">
    <v-dialog
      v-model="dialog2"
      max-width="500px"
      persistent
    >
      <v-card class="text-h6 justify-content-center">
        <v-card-title class="text-h6 justify-content-center">
          Seleccione punto de venta
        </v-card-title>
        <v-card-text>
          <v-select
            name="itemVenta"
            v-model="itemVenta"
            autofocus
            :items="optionsPuntoVenta"
            label="Punto de venta de ubicación"
            item-text="nombre"
            return-object
            dense
            required
            :rules="[sucursal.required]"
          ></v-select>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="primary"
            name="guardarSucursal"
            type="submit"
            @click="puntoVentaChange(itemVenta)"
          >
            guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title class="align-start">
        <span class="font-weight-semibold">Estadisticas</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          class="me-n3 mt-n2"
        >
          <v-icon>
            {{ icons.mdiDotsVertical }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-subtitle class=" mt-n5">
        <span
          class="font-weight-semibold text--primary me-1"
        >Punto de venta actual:</span>
        <span
          class="font-weight-semibold text--primary me-1"
          style="color: green !important"
        >{{ puntoVentaName }}</span>
      </v-card-subtitle>
      <v-card-subtitle class=" mt-n5">
        <!-- <span class="font-weight-semibold text--primary me-1">Usted tiene un total 48.5% en ventas</span> -->
      </v-card-subtitle>
      <v-card-subtitle class="mb-8">
        <span>😎 vas muy bien "{{ userSesion }}" </span>
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col
            v-for="data in statisticsData"
            :key="data.title"
            cols="6"
            md="3"
            class="d-flex align-center mb-8"
          >
            <v-avatar
              size="44"
              :color="resolveStatisticsIconVariation (data.title).color"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ resolveStatisticsIconVariation (data.title).icon }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                {{ data.title }}
              </p>
              <h3 class="text-xl font-weight-semibold">
                {{ data.total }}
              </h3>
            </div>
          </v-col>

          <hr>

          <v-col
            cols="12"
            class="mt-15"
          >
            <v-card>
              <v-card-title class="pb-0">
                <span>Gestiones del mes</span>
                <v-spacer></v-spacer>
                <span class="subtitle-2">
                  <v-icon
                    size="18"
                    class="me-1"
                  >
                    {{ icons.mdiCalendarBlankOutline }}
                  </v-icon>
                  <span>2022-09-01 to 2022-09-30</span>
                </span>
              </v-card-title>
              <!-- <v-card-text>
                <h3>$880.00,00</h3>
              </v-card-text> -->
              <v-card-text>
                <v-row>
                  <v-col
                    md="8"
                  >
                    <vue-apex-charts
                      type="bar"
                      height="400"
                      :options="apexChatData.columnChart.chartOptions"
                      :series="apexChatData.columnChart.series"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, mdiDotsVertical, mdiLabelOutline, mdiCircle } from '@mdi/js'
import VueApexCharts from 'vue-apexcharts'
import { ref } from '@vue/composition-api'
import apexChatData from './apexChartData'

export default {
  components: { VueApexCharts },
  setup() {
    const statisticsData = [
      {
        title: 'Clientes del mes',
        total: '48',
      },
      {
        title: 'Ventas del mes',
        total: '48',
      },
      {
        title: 'Renuncias del mes',
        total: '5',
      },
      {
        title: 'Gestiones del mes',
        total: '53',
      },
    ]

    const resolveStatisticsIconVariation = data => {
      if (data === 'Ventas del mes') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'Clientes del mes') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Renuncias del mes') return { icon: mdiLabelOutline, color: 'warning' }
      if (data === 'Gestiones del mes') return { icon: mdiCircle, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    const userSesion = ref(null)
    const dialog2 = ref(true)

    const optionsPuntoVenta = ref([])
    const itemVenta = ref(null)
    const puntoVentaName = ref('')

    return {
      statisticsData,
      apexChatData,
      resolveStatisticsIconVariation,
      userSesion,
      dialog2,
      optionsPuntoVenta,
      itemVenta,
      puntoVentaName,

      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
      },
      sucursal: {
        required: value => !!value || 'Punto de venta es requerida.',
      },
    }
  },
  created() {
    this.user()

    // this.puntoVenta()
  },
  methods: {
    user() {
      const user = JSON.parse(localStorage.getItem('userData'))
      this.userSesion = user.usuario
      const sucursal = JSON.parse(localStorage.getItem('userSucursal'))
      if (sucursal.length === 0 || sucursal.length === null) {
        this.puntoVenta()
      } else {
        this.dialog2 = false
        this.puntoVentaName = sucursal.nombre
      }
    },
    close() {
      const sucursal = JSON.parse(localStorage.getItem('userSucursal'))
      if (sucursal.length === 1) {
        this.dialog2 = false
      } else {
        this.dialog2 = true
      }
    },
    puntoVenta() {
      const user = JSON.parse(localStorage.getItem('userData'))
      const sucursal = JSON.parse(localStorage.getItem('userSucursal'))

      if (
        (user.sucursales.length > 1 && sucursal.length === 0) ||
        (user.sucursales.length > 1 && sucursal.length === null)
      ) {
        this.optionsPuntoVenta = user.sucursales
        this.dialog2 = true
      } else {
        localStorage.setItem('userSucursal', JSON.stringify(user.sucursales[0]))
        this.dialog2 = false
      }
    },

    puntoVentaChange(item) {
      localStorage.setItem('userSucursal', JSON.stringify(item))
      if (item !== null) {
        this.dialog2 = false
        this.puntoVentaName = item.nombre
      } else {
        this.dialog2 = true
      }
    },
  },
}
</script>
