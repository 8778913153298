import themeConfig from '@themeConfig'

const $themeColors = themeConfig.themes.light

// heat chart data generator
// function generateDataHeatMap(count, yrange) {
//   let i = 0
//   const series = []
//   while (i < count) {
//     const x = `w${(i + 1).toString()}`
//     const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min

//     series.push({
//       x,
//       y,
//     })
//     // eslint-disable-next-line no-plusplus
//     i++
//   }

//   return series
// }

// colors
const chartColors = {
  column: {
    series1: '#246db1',
    series2: '#ffb400',
    bg: '#ffb4004f',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#e0cffe',
    series2: '#b992fe',
    series1: '#ab7efd',
  },
}

export default {
  columnChart: {
    series: [
      {
        name: 'Ventas',
        data: [9, 13, 9, 9, 9],
      },
      {
        name: 'Renuncias',
        data: [9, 13, 9, 9, 9],
      },
    ],
    chartOptions: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: [chartColors.column.series1, chartColors.column.series2],
      plotOptions: {
        bar: {
          columnWidth: '10%',
          colors: {
            backgroundBarColors: [
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
            ],
            backgroundBarRadius: 10,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '10px',
        fontFamily: '"Inter", sans-serif',
        horizontalAlign: 'left',
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
            with: '15%',
          },
        },
      },
      xaxis: {
        categories: [
          'CUOTA SEGURA HITES',
          'DESGRAVAMEN TOTAL HITES',
          'ASISTENCIA SONRISA HITES',
          'ASISTENCIA TELEMEDICINA HITES',
          'ASISTENCIA SALA DE URGENCIA HITES',
        ],
      },
      yaxis: {
        // opposite: isRtl,
      },
      fill: {
        opacity: 1,
      },
    },
  },
}
